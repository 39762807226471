import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/How_To_Clear_Your_Browsing_History/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Erase your Browsing History in Opera",
  "path": "/Quick_Installation/How_To_Clear_Your_Browsing_History/Opera/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "Erase your Browsing History in Opera",
  "image": "./QI_SearchThumb_ClearBrowsingHistory_Opera.png",
  "social": "/images/Search/QI_SearchThumb_ClearBrowsingHistory_Opera.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Clear_History_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Clear your Browser History' dateChanged='2017-11-24' author='Mike Polinowski' tag='INSTAR IP Camera' description='Clear your Browser History' image='/images/Search/QI_SearchThumb_ClearBrowsingHistory.png' twitter='/images/Search/QI_SearchThumb_ClearBrowsingHistory.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Leeren_des_Browserverlaufs/Opera/' locationFR='/fr/Quick_Installation/How_To_Clear_Your_Browsing_History/Opera/' crumbLabel="Opera" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "erase-your-browsing-history-in-opera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#erase-your-browsing-history-in-opera",
        "aria-label": "erase your browsing history in opera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Erase your Browsing History in Opera`}</h1>
    <p>{`Sometimes your camera´s WebUI might not behave the way you want. You updated your camera´s software, but it still shows you the old version? An update should bring new functions to the user interface - but they are nowhere to be seen? Or you simply cannot change a setting? Your Browser might have cached old parts of the user interface and refuses to reload the new or updated files. Here is how you can get rid of your browsers browsing history in several common browsers:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a99e4c55d501b0293b1079abebfdb723/5ff7e/Opera-Clean-History_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "110.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEs0lEQVQ4y3WU+W8TVxDH9x8EkYRDKhAQIoCqShUFFbUiQKtC218CqsAJIQFREJAEckI5EgjQOAlxfO7h9bE+1vZ67fWZGCJxfap9CUcr+qSPvvN2pNk38+aN9PB4N0/u3ePx7CwPHz1icnyS0HKIQCBMxC8TCssEfCE0WadabxDVY1zwXODcuXOcPXuWnp6efyEZqRSKorL0YonAsp+553MoQZlEIknJLmPbNkbSwDDSNJorOJUqC/MLeOe8zM8vsuTz4ff7Wd5AarVaFHJ59KhOxanglB2KBYtSqUyz2RTkcjlC4TCJZIparY5TcihaNlWnzus3b3DXe9aXlDQMorKGFtForqyIACsb2mg0qNfrmKbJ2NgYY+MTjG8wOTXF2Ng4o6NjTExMfkRKpVOYmSz5bJ5qtfpFCoUCl/r76He56GFw8BIjwzeF7fGcZ3j4FrdvDzEyMoSUSCTQ1Si6oqGEZNKptDhZrVYTuAEzmQwDAzfweK7T13ed3t7/cu0jUkSOoMkqWljFsiyRbj6XIxGNE41opOIGRavIgQP9bN58no4ODx0dF2hvX+fD3lV3L8mKghqRSSUMYppONmtS20g1Y6QxYklsu8ShQ4O0tfWxfXs/W7deZNu2dT63XZ8UDAZFwKgSFZdTKFi8fPWS1dVVEdQpl8UtHzw4QHt7H6dPT3H48C26j9/h1KlxTp4ap/v4KD8cG+HI0WGkcDiMpqoimOKPoGs6US1KSvTd+k27NXRPuGVLL7t3D7Jz5wA7vxoQ2tl5mV27BsV+x45+JJ/PhxKJEFN1SnYJx3FEzVz90D5uc7snbGvrZevWPjo6PtHe3vvRdn2S+/ekHkcORMhmspjprEixkM+LGlYq6/X8EHD79k81+xKSm5JdtDEzJmbWJJsxSRkp0T5GIkUhb1Es2iJlt4b/F/DDd6leq5LPZdEiMgk9RjqZZGWlST6bJaZEyRgp7GKRrq5LbNp0QaTY1ubZ4HPbI3xSqVLDMEwWvcsEAipWqUK50qDk1LBLVaHJVJajR6+zd+8Vurr+ZP/+q4J9+658tLu6rtLZOYj09dQa3zx4z8nZt3x7/zWdE+/YM/FWsHfyLTvH3/HT4xa5XBE5qLHo9bO8FEZRYlhWGSWksbQQJG1kGB2dR/ImmzwIWNydT7OUfcWzRIvnyRbPEqtCn8RWmI0UWGk2yaYzRCOq6Ag5JJOMxcU7t/IW2ZTB5IQX6VWzQrNsUTYzNCs2rVqJ1WqJtWYF1+dqo1bm5VoLx7EpWnmBbVuUy0XqjSqNRpXXr1e5e38JqVKtYZo5dC2GEtGIBGVURafszkaXeoNiPIkyvcTz8WfM3HzA9LW/mLnxiMjTkMD/OEhsQeNWzwhSrV6nYOZEe7jD1m1mF3cOum+6sfaK2sILjhwa5rvTL/jldy/dZ+Y58auXk2fmOPbz3+z5/hn7fvRyou03pFw+j6qoJOIJ3FEW03Ux/t3mdqdO3ioQXPRTUeOYfpn4nI/kvF9o3OvDDMg40QSNeJyJy3fXU87lCzhOGb8vgB6NoqoqmqaJV7K21uLK1fvcmVhkasrL0NAMt+/MMjzyhJu3ppm8O8+DaT8zTwP0/DHJP/wejdny3yiGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a99e4c55d501b0293b1079abebfdb723/e4706/Opera-Clean-History_01.avif 230w", "/en/static/a99e4c55d501b0293b1079abebfdb723/99090/Opera-Clean-History_01.avif 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a99e4c55d501b0293b1079abebfdb723/a0b58/Opera-Clean-History_01.webp 230w", "/en/static/a99e4c55d501b0293b1079abebfdb723/f3a60/Opera-Clean-History_01.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a99e4c55d501b0293b1079abebfdb723/81c8e/Opera-Clean-History_01.png 230w", "/en/static/a99e4c55d501b0293b1079abebfdb723/5ff7e/Opera-Clean-History_01.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a99e4c55d501b0293b1079abebfdb723/5ff7e/Opera-Clean-History_01.png",
              "alt": "Erase your Browsing History in Opera",
              "title": "Erase your Browsing History in Opera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9b010a20f4acb08bb0d0d9e04cb6d63c/5ff7e/Opera-Clean-History_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "119.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAYAAAD6S912AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEKElEQVQ4y42Va3PaRhSG+bH9FcmXtjPt9xSc0sTYTZxb47YZTzJ2HGM74GADTppiC92FhJEwIJAQN9NJZ57OrhPqzKQdf9g5K7R6eN+z5+ym3t27T61WQ9M19vdfo9Tq2FYD23AwdBvXaTJMRvhBGz/w6fV6dLpdOp3O5+P8XMZUMp3RC/ucd7rEcUIYDuh0eyTJmP4gwvOaRHHCME5oB226HfFuxHw+Z3ZxwcXFhYzjDx+YzOekxEfv/ngv1ZUOD9nb32cnn+fg4I2cP3/xgu3tV7x+XSCf32U3v0epdEi1WqVSqVCpVqmWyzgbG2iFAinXa/J0fZ1sNsvKSo5cLsfq6qocd+7eIZ1Ok1nKkMmkSacz3PrhFj9ms3Ldcm6Z5VyOu9ksmzdvsvrN16SUukqpVKJ93uPM7xK0AkzDxDJMXEfk0sY2LRp2A8dp0LAdHPvyN0MzsA2LIGgTjhKeb26TMkyT6vEJ6ttHHL/8Csd0KO4UqJYqHL+pUBWjWOZt6ZiD3QKVgwqHe28oHxxx8v4ErabitwL+/nDB2tomKdOyOCofo9XynBzdo2FbOJaD53p4DfdSmSV228Vv+QR+QMtryXkyGpGMEqIoZjYb8/SXV6Q03ZCWk9GMyQy8ZpN67RRN0dBVHVs3pc0wDJnNZkynUybTCdPZlOFwSBzHC+Cv6zvCssXh4SFRNGCUDPE8D+XPU5Sagq5q6IqKY9oLtQ2nQdNrytx2ZXklDAYR8/mEtbUtUt1eSLlcIYoiRqMRrutKhXpdx9BMmXRLM6XVtt+WVjvnHfnc7/elSvGtAD58uEUqHiaUK/8Cr1o2FF2qM3VTvvs0RN5EvGpZAB882CIVxcPPgZ4ngepp/dJ2XcNQDQmN4ngBuTr+F+h6LpqiSoCASaCio57UZb1dBV5boVZXMeqGBIkCFtB6TaGuqIu8XV+h66LXVSzdwlR12TGWbsqc2qbDaDxeAK+lUACFQkvuriHzJ+rQVC/noiv64ecq/xMoaqrdDj7a1DF1A6OuS8vaqYbrerIGe93e9YG+78tiVk9VqVR2jKLJvg3DvuwUsU4Ar2359P0J9RNFRlW5LBsxb1gOjmHL/IquGQwGEvipU74IbJ41sXRDtpiwe3bWYpQkcnej/mABEerE+vF4TBwPvwwUi8J+H7/VkgeDZVo4rovbcCWwFw0IgkDWqDgzTdOS+T5rthiP48vjSwDFUf7pXwVYXDZiAzyxu+sbaBsv8fJF2tu7tLwmuiaAljxwbdOm4XgMhwPu3/8IPDo6IvpoZWFnMmEchoQ/raCvPUG594hoeYW+79JoWHTOfSbTIb1OgKVpzP8a8uTxFilxRRYKRfb29igWixQKBXkhHRwU2dzcIf3dCplvc5fj+59ZWvqd27efyZjJ/Cbj0u1nZLPPuHFjlX8Ax7OleNFyo/IAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b010a20f4acb08bb0d0d9e04cb6d63c/e4706/Opera-Clean-History_02.avif 230w", "/en/static/9b010a20f4acb08bb0d0d9e04cb6d63c/99090/Opera-Clean-History_02.avif 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9b010a20f4acb08bb0d0d9e04cb6d63c/a0b58/Opera-Clean-History_02.webp 230w", "/en/static/9b010a20f4acb08bb0d0d9e04cb6d63c/f3a60/Opera-Clean-History_02.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b010a20f4acb08bb0d0d9e04cb6d63c/81c8e/Opera-Clean-History_02.png 230w", "/en/static/9b010a20f4acb08bb0d0d9e04cb6d63c/5ff7e/Opera-Clean-History_02.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9b010a20f4acb08bb0d0d9e04cb6d63c/5ff7e/Opera-Clean-History_02.png",
              "alt": "Erase your Browsing History in Opera",
              "title": "Erase your Browsing History in Opera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      